<template>
  <div>
    <h1>Bills</h1>

    <b-form-group>
      <b-form-select
        v-model="payment_provider"
        :options="searchOptionsPaymentMode"
      />
    </b-form-group>

    <b-form-group>
      <b-form-select
        v-model="searchField"
        :options="searchOptionsDropdown"
      />
    </b-form-group>
    <b-form-group>
      <b-form-input
        v-model="searchTerm"
        type="text"
        placeholder="Type to Search"
      />
    </b-form-group>

    <b-button @click="search">
      Search
    </b-button>

    <div class="mt-2">
      <h4>Search Result</h4>
      <div
        v-if="!isLoading && searchResult.length === 0"
        style="height: 300px"
        class="d-flex justify-content-center align-items-center border"
      >
        <h5>Showing No Result</h5>
      </div>
      <div
        v-if="isLoading"
        style="height: 300px"
        class="d-flex justify-content-center align-items-center border"
      >
        <h5>Loading results...</h5>
      </div>

      <div
        v-if="!isLoading"
      >
        <b-row>
          <template v-for="(result, idx) in searchResult">

            <b-col
              :key="`result-card-${idx}`"
              cols="12"
              lg="6"
            >
              <b-card>
                {{ result.CustomerBill.invoice_ref }} <br>
                Status: {{ result.CustomerBill.invoice_status }} <br>

                <!--                {{ psStatusCheckData }} br-->
                <template v-if="psStatusCheckData && result.CustomerBill.invoice_status !== 'SUCCESS'">

                  <template v-if="result.CustomerBill.invoice_status_id === 11">
                    Reason: {{ psStatusCheckData.fpx_debit_auth_code }} - {{ translateText('stopcheck11'+psStatusCheckData.fpx_debit_status) }}
                  </template>
                  <template v-else-if="result.CustomerBill.invoice_status_id === 12">
                    Reason: {{ psStatusCheckData.fpx_debit_auth_code }} - {{ translateText('stopcheck12'+psStatusCheckData.fpx_debit_status) }}
                  </template>
                  <template v-else-if="result.CustomerBill.invoice_status_id === 13">
                    Reason: {{ psStatusCheckData.fpx_debit_auth_code }} - {{ translateText('stopcheck13'+psStatusCheckData.fpx_debit_status) }}
                  </template>
                  <template v-else-if="result.CustomerBill.invoice_status_id === 14">
                    Reason: {{ psStatusCheckData.fpx_debit_auth_code }} - {{ translateText('stopcheck14'+psStatusCheckData.fpx_debit_status) }}
                  </template>
                  <template v-else-if="result.CustomerBill.invoice_status_id === 15">
                    Reason: {{ psStatusCheckData.fpx_debit_auth_code }} - {{ translateText('stopcheck15'+psStatusCheckData.fpx_debit_status) }}
                  </template>

                  <template v-else>
                    Reason: {{ psStatusCheckData.fpx_debit_auth_code }} - {{ translateText(psStatusCheckData.fpx_debit_status) }}
                  </template>

                </template>

                <template #footer>
                  <div class="d-flex justify-content-end">
                    <b-button
                      v-if="result.CustomerBill.transaction_invoice_no"
                      @click="triggerCallbackData(result.CustomerBill.transaction_invoice_no)"
                    >
                      Callback
                    </b-button>
                    <b-button
                      class="ml-1"
                      @click="getPSTxnStatus(result.CustomerBill)"
                    >
                      <template v-if="isLoadingPSTxn">
                        Fetching -
                      </template>
                      Get PS Txn Status
                    </b-button>
                  </div>
                </template>
              </b-card>

            </b-col>

            <b-col
              :key="`detail-result-${idx}`"
              cols="12"
              lg="6"
            >

              <b-card>
                <template
                  #header
                  class="d-flex justify-content-between"
                >
                  <h4>Search Result Details</h4>

                  <b-button
                    size="sm"
                    @click="showRaw"
                  >
                    Show Raw
                  </b-button>
                </template>

                <div v-if="showRawContent">
                  <pre>
                    {{ result }}
                  </pre>
                </div>
                <div :key="`detail-result-fields-${idx}`">
                  <b-form-group label="Account Name">
                    <b-input-group size="sm">
                      <b-form-input
                        :value="`${result.account_name} (${result.name})`"
                        readonly
                        type="text"
                        placeholder="Type to filter"
                      />
                      <b-input-group-append>
                        <b-button
                          @click="doCopy(result.account_name)"
                        >Copy</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group label="Invoice Status">
                        <b-input-group size="sm">
                          <b-form-input
                            v-model="result.CustomerBill.invoice_status"
                            readonly
                            type="text"
                            placeholder="Type to filter"
                          />
                          <b-input-group-append>
                            <b-button
                              @click="doCopy(result.CustomerBill.invoice_status)"
                            >Copy</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group label="Payment Method">
                        <b-input-group size="sm">
                          <b-form-input
                            v-model="result.payment_method"
                            readonly
                            type="text"
                            placeholder="Type to filter"
                          />
                          <b-input-group-append>
                            <b-button
                              @click="doCopy(result.payment_method)"
                            >Copy</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group label="Payment Mode">
                        <b-input-group size="sm">
                          <b-form-input
                            v-model="result.payment_mode"
                            readonly
                            type="text"
                            placeholder="Type to filter"
                          />
                          <b-input-group-append>
                            <b-button
                              @click="doCopy(result.payment_mode)"
                            >Copy</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-form-group label="Customer Full Name">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="result.CustomerBill.full_name"
                        readonly
                        type="text"
                        placeholder="Type to filter"
                      />
                      <b-input-group-append>
                        <b-button
                          @click="doCopy(result.CustomerBill.full_name)"
                        >Copy</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label="Amount">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="result.CustomerBill.total"
                        readonly
                        type="text"
                        placeholder="Type to filter"
                      />
                      <b-input-group-append>
                        <b-button
                          @click="doCopy(result.CustomerBill.total)"
                        >Copy</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label="Invoice No">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="result.CustomerBill.invoice_no"
                        readonly
                        type="text"
                        placeholder="Type to filter"
                      />
                      <b-input-group-append>
                        <b-button
                          @click="doCopy(result.CustomerBill.invoice_no)"
                        >Copy</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label="Invoice Ref">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="result.CustomerBill.invoice_ref"
                        readonly
                        type="text"
                        placeholder="Type to filter"
                      />
                      <b-input-group-append>
                        <b-button
                          @click="doCopy(result.CustomerBill.invoice_ref)"
                        >Copy</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label="TXN Invoice #">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="result.CustomerBill.transaction_invoice_no"
                        readonly
                        type="text"
                        placeholder="Type to filter"
                      />
                      <b-input-group-append>
                        <b-button
                          @click="doCopy(result.CustomerBill.transaction_invoice_no)"
                        >Copy</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <!--          <b-button @click="createTelegramReply(result)">-->
                  <!--            As Telegram Reply-->
                  <!--          </b-button>-->
                  <!--                  <b-button-->
                  <!--                    @click="getPSTxnStatus(result.CustomerBill)"-->
                  <!--                  >-->
                  <!--                    As Telegram Reply-->
                  <!--                  </b-button>-->
                </div>
              </b-card>
            </b-col>

          </template>
        </b-row>
      </div>

    </div>

  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: {},
  mixins: [humaniseMixin],
  data() {
    return {
      isLoading: false,
      isLoadingPSTxn: false,
      showRawContent: false,
      searchTerm: 'MY24092611035392',
      searchField: 'invoice_ref',
      searchResult: [],
      psStatusCheckData: null,

      payment_provider: 'SWITCH_WEBPAY',
      x: {
        payout_account_number: '',
        switch_transaction_fee: 0,
        created_at: '',
        transaction_status: '',
        account_id: 0,
        updated_at: '',
        recipient_name: null,
        data: '',
        id: 0,
        recipient_reference: null,
        callback_data: '',
        switch_invoice_id: '',
        name_confident_level: null,
        api_key: '',
        merchant_invoice_id: '',
        value: 0,
        external_invoice_ref: '',
        transaction_invoice_no: '',
        transaction_fee: 0,
        is_recon: false,
        payout_invoice_no: '',
        record_status: 1,
        name: '',
      },
      extraParam: {
        invoice_status: ['SUCCESS', 'PENDING', 'FAILED', 'VOID_PAYMENT'],
      },

      searchOptionsDropdown: [
        { value: 'transaction_invoice_no', text: 'TXN Invoice #' },
        { value: 'invoice_no', text: 'Invoice #' },
        { value: 'full_name', text: 'Customer Name' },
        { value: 'email', text: 'Customer Email' },
        { value: 'invoice_ref', text: 'Invoice Ref' },
        { value: 'phone_number', text: 'Customer Phone Number' },
      ],
      searchOptionsPaymentMode: [
        {
          // SWITCH_WEBPAY
          text: 'All',
          value: null,
        },
        {
          // SWITCH_WEBPAY
          text: 'FPX',
          value: 'SWITCH_WEBPAY',
        },
        {
          // SWITCH_BNPL
          text: 'BNPL',
          value: 'SWITCH_BNPL',
        },
        {
          // SWITCH_CC
          text: 'Credit Card',
          value: 'SWITCH_CC',
        },
        {
          // SWITCH_STRIPE_CC
          text: 'Stripe',
          value: 'SWITCH_STRIPE_CC',
        },
        {
          // SWITCH_EWALLET
          text: 'BoldPay E-wallet',
          value: 'SWITCH_EWALLET',
        },
        {
          // SWITCH_EWALLET
          text: 'SenangPay E-wallet',
          value: 'SWITCH_EWALLET',
        },
        {
          // SWITCH_CRYPTOCURRENCY
          text: 'Cryptocurrency',
          value: 'SWITCH_CRYPTOCURRENCY',
        },
        {
          // SWITCH_PAYPAL
          text: 'Paypal',
          value: 'SWITCH_PAYPAL',
        },
        {
          // SWITCH_STRIPE_CC_EMBED
          text: 'CC Embed',
          value: 'SWITCH_STRIPE_CC_EMBED',
        },
      ],

    }
  },

  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Helper' },
      { title: 'Bills' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
  },

  methods: {
    triggerCallbackData(invoiceNo) {
      console.log(invoiceNo)

      const payload = {
        invoice_nos: [invoiceNo],
      }

      this.$store.dispatch('general/omniCallbackBulkCallback', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Successfully Triggered',
            text: 'Callback Triggered',
            icon: 'success',
          })
        } else {
          const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
          this.$swal.fire({
            title: `Error - ${res.data.response_code}`,
            html: desc,
            icon: 'error',
          })
        }
      })
    },
    translateText(str) {
      let newText = ''
      switch (str) {
        case 'account verification failed please try again':
          newText = 'Account verification failed'
          break
        case 'stopcheck11Transaction Pending':
          newText = 'Expired'
          break
        case 'stopcheck12Transaction Pending':
          newText = 'Expired'
          break
        case 'stopcheck13Transaction Pending':
          newText = 'Expired'
          break
        case 'stopcheck14Transaction Pending':
          newText = 'Expired'
          break
        case 'stopcheck15Transaction Pending':
          newText = 'Expired'
          break

        default:
          newText = str
          break
      }
      console.log(newText)
      return newText
    },
    showRaw() {
      this.showRawContent = !this.showRawContent
    },
    getPSTxnStatus(customerBills) {
      console.log(customerBills)
      this.isLoadingPSTxn = true
      const payload = {
        transaction_invoice_no: customerBills.transaction_invoice_no,
      }

      this.psStatusCheckData = null
      this.$store.dispatch('adminCustomerBills/fpxFailedReason', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.psStatusCheckData = {
            invoice_status_id: customerBills.invoice_status_id,
            invoice_ref: customerBills.invoice_ref,
            ...res.data.data,
          }
          this.copyAsTelegram(this.psStatusCheckData)

          this.isLoadingPSTxn = false
        }
      })
    },

    copyAsTelegram(selectedRowData) {
      let translatedReason = ''
      if (selectedRowData.invoice_status === 'SUCCESS') {
        const successContent = `${selectedRowData.invoice_ref} \nStatus: ${selectedRowData.invoice_status}`
        navigator.clipboard.writeText(successContent)
      } else {
        if (selectedRowData.invoice_status_id === 11) translatedReason = `stopcheck11${selectedRowData.fpx_debit_status}`
        else if (selectedRowData.invoice_status_id === 12) translatedReason = `stopcheck12${selectedRowData.fpx_debit_status}`
        else if (selectedRowData.invoice_status_id === 13) translatedReason = `stopcheck13${selectedRowData.fpx_debit_status}`
        else if (selectedRowData.invoice_status_id === 14) translatedReason = `stopcheck14${selectedRowData.fpx_debit_status}`
        else if (selectedRowData.invoice_status_id === 15) translatedReason = `stopcheck15${selectedRowData.fpx_debit_status}`
        else translatedReason = selectedRowData.fpx_debit_status

        const content = `${selectedRowData.invoice_ref} \nStatus: ${selectedRowData.invoice_status} \nReason: ${selectedRowData.fpx_debit_auth_code} - ${this.translateText(translatedReason)}`
        navigator.clipboard.writeText(content)
        // this.doCopy2(content)
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },

    isValidJSON(str) {
      try {
        JSON.parse(str)
        console.log(true)
        return true
      } catch (e) {
        console.log(false)
        return false
      }
    },
    createTelegramReply(result) {
      this.doCopy(`${result.CustomerBill.invoice_ref} - ${result.CustomerBill.invoice_status}`)
    },
    search() {
      const payload = {
        startDate: '01-01-1990',
        endDate: '01-01-2100',
        limit: 100,
        skip: 0,
        search: {
          search_enable: true,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: 'asc',
        },
        ...this.extraParam,
        payment_provider: this.payment_provider,
      }

      this.isLoading = true

      this.$store.dispatch('adminCustomerBills/getCustomerBillsList', payload).then(res => {
        this.searchResult = res.data.data.list.data

        this.isLoading = false
      })
    },
  },
}
</script>

<style>

</style>
